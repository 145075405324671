import React, { lazy} from "react";
import { Routes, Route} from "react-router-dom";
import Home from "./Pages/Home/Home";
const Customers = lazy(() => import("./Pages/Customers/Customers"));
const About = lazy(() => import("./Pages/About/About"));
const Contact = lazy(() => import("./Pages/Contact/Contact"));
const Signup = lazy(() => import("./Pages/Signup/Signup"));
const Terms = lazy(() => import("./Pages/Terms/Terms"));
const Privacy = lazy(() => import("./Pages/Privacy/Privacy"));
//resource
const Views = lazy(()=> import("./Pages/Views/Views"));
const Features = lazy(()=> import("./Pages/Features/Features"));
const Alertnotification = lazy(()=> import("./Pages/Alertnotification/Alertnotification"));
const Workflow = lazy(()=> import("./Pages/Workflow/Workflow"));
const Documents = lazy(()=> import("./Pages/Documents/Documents"));
const Security = lazy(()=> import("./Pages/Security/Security")) ;
const Bianalytics = lazy(()=> import("./Pages/Bianalytics/Bianalytics"));
const Customizations = lazy(()=> import("./Pages/Customizations/Customizations"));
const BoardView = lazy(()=> import("./Pages/BoardView/BoardView"));
const Notfound = lazy(() => import("./components/NotFound/Notfound"));
//hr
const HrmsLayout = lazy(() => import("./module/Hrms/HrmsLayout/HrmsLayout"));
const HrmsHome = lazy(() => import("./module/Hrms/HrmsHome/HrmsHome"));

const CoreHrLayout = lazy(() => import("./module/Hrms/CoreHr/CoreHrLayout/CoreHrLayout"));
const CoreHrHome = lazy(() => import("./module/Hrms/CoreHr/CoreHrHome/CoreHrHome"));
const EmployeeDatabase = lazy(() => import("./module/Hrms/CoreHr/EmployeeDatabase/EmployeeDatabase"));
const EssPortal = lazy(() => import("./module/Hrms/CoreHr/EssPortal/EssPortal"));
const Document = lazy(() => import("./module/Hrms/CoreHr/Document/Document"));
const Workflows = lazy(() => import("./module/Hrms/CoreHr/Workflows/Workflows"));
const HrReports = lazy(() => import("./module/Hrms/CoreHr/HrReports/HrReports"));

// const FieldForceLayout = lazy(() => import("./module/Hrms/FieldForce/FieldForceLayout/FieldForceLayout"));
// const FieldForce = lazy(() => import("./module/Hrms/FieldForce/FieldForceHome/FieldForceHome"));

// const AttendanceLayout = lazy(() => import("./module/Hrms/Attendance/AttendanceLayout/AttendanceLayout"));
// const AttendanceHome = lazy(() => import("./module/Hrms/Attendance/AttendanceHome/AttendanceHome"));

// const PayrollLayout = lazy(() => import("./module/Hrms/Payroll/PayrollLayout/PayrollLayout"));
// const PayrollHome = lazy(() => import("./module/Hrms/Payroll/PayrollHome/PayrollHome"));

// const HrmsExpenseLayout = lazy(() => import("./module/Hrms/HrmsExpense/HrmsExpenseLayout/HrmsExpenseLayout"));
// const HrmsExpenseHome = lazy(() => import("./module/Hrms/HrmsExpense/HrmsExpenseHome/HrmsExpenseHome"));

// const HrmsTaskLayout = lazy(() => import("./module/Hrms/HrmsTasks/HrmsTaskLayout/HrmsTaskLayout"));
// const HrmsTaskHome = lazy(() => import("./module/Hrms/HrmsTasks/HrmsTaskHome/HrmsTaskHome"));

// const HrmsEngagementLayout = lazy(() => import("./module/Hrms/HrmsEngagement/HrmsEngagementLayout/HrmsEngagementLayout"));
// const HrmsEngagementHome = lazy(() => import("./module/Hrms/HrmsEngagement/HrmsEngagementHome/HrmsEngagementHome"));

// const HrmsPriceLayout = lazy(() => import("./module/Hrms/HrmsPricing/HrmsPriceLayout/HrmsPriceLayout"));
// const HrmsPricing = lazy(() => import("./module/Hrms/HrmsPricing/HrmsPricing/HrmsPricing"));


//hr old
const HrLayout = lazy(()=> import("./module/HR/HrLayout/HrLayout"));
// const HrHome = lazy(()=> import("./module/HR/HrHome/HrHome"));
// const CoreHr = lazy(()=> import("./module/HR/CoreHr/CoreHr"));
const FieldForce = lazy(()=> import("./module/HR/FieldForce/FieldForce"));
const Attendance = lazy(()=> import("./module/HR/Attendance/Attendance"));
const Payroll = lazy(()=> import("./module/HR/Payroll/Payroll"));
const Expense = lazy(()=> import("./module/HR/Expense/Expense"));
const Tasks = lazy(()=> import("./module/HR/Tasks/Tasks"));
const Engagement = lazy(()=> import("./module/HR/Engagement/Engagement"));
const Pricing = lazy(()=> import("./module/HR/Pricing/Pricing"));
//expense
const ExpenseLayout = lazy(()=> import("./module/Expense/ExpenseLayout/ExpenseLayout"));
const ExpenseHome = lazy(()=> import("./module/Expense/ExpenseHome/ExpenseHome"));
//sales
const SalesLayout = lazy(() => import("./module/Sales/SalesLayout/SalesLayout"));
const SalesHome = lazy(() => import("./module/Sales/SalesHome/SalesHome"));
const SalesFeauterLayout = lazy(() => import("./module/Sales/Feauters/SalesFeauterLayout/SalesFeauterLayout"));
const SalesManagement = lazy(() => import("./module/Sales/Feauters/SalesManagement/SalesManagement"));
const AccountsReceivable = lazy(()=> import("./module/Sales/Feauters/AccountsReceivable/AccountsReceivable"));
const BillingPayment = lazy(()=> import("./module/Sales/Feauters/BillingPayment/BillingPayment"));
const OrderExecution = lazy(()=> import("./module/Sales/Feauters/OrderExecution/OrderExecution"));
const Invoicving = lazy(()=> import("./module/Sales/Feauters/Invoicving/Invoicving"));
const TenderDeposit = lazy(()=> import("./module/Sales/Feauters/TenderDeposit/TenderDeposit"));
const SalesPerformance = lazy(()=> import("./module/Sales/Feauters/SalesPerformance/SalesPerformance"));
const DocumentManagement = lazy(()=> import("./module/Sales/Feauters/DocumentManagement/DocumentManagement"));
const BusinessManagement = lazy(()=> import("./module/Sales/Feauters/BusinessManagement/BusinessManagement"));

//teams
const TeamsHome = lazy(()=> import("./module/Teams/TeamsHome/TeamsHome"));
const TeamsLayout = lazy(()=> import("./module/Teams/TeamsLayout/TeamsLayout"));
//visit
const VisitLayout = lazy(()=> import("./module/Visit/VisitLayout/VisitLayout"));
const VisitHome = lazy(()=> import("./module/Visit/VisitHome/VisitHome"));

//desk
const DeskHome = lazy(()=> import("./module/Desk/DeskHome/DeskHome"));
const DeskLayout = lazy(()=> import("./module/Desk/DeskLayout/DeskLayout"));
//Tenders
const TendersHome = lazy(()=> import("./module/Tenders/TendersHome/TendersHome"));
const TendersLayout = lazy(()=> import("./module/Tenders/TendersLayout/TendersLayout"));
//Project
const ProjectHome = lazy(()=> import("./module/Project/ProjectHome/ProjectHome"));
const ProjectLayout = lazy(()=> import("./module/Project/ProjectLayout/ProjectLayout"));

// const AdminLayout = lazy(()=> import("./AdminLayout"));
// const Login = lazy(()=> import("./Admin/Login/Login"));
// const ProductList = lazy(()=> import("./Admin/ProductList/ProductList"));
// const PostData = lazy(()=> import("./Admin/PostData/PostData"));
// const ImageCompressor = lazy(()=> import("./Admin/ImageCompressor/ImageCompressor"));
// const ImageToPdfConverter = lazy(()=> import("./Admin/ImageToPdfConverter/ImageToPdfConverter"));
// const UserLayout = lazy(()=> import("./Admin/Users/UserLayout"));
// const UserList = lazy(()=> import("./Admin/Users/UserList"));
// const UserForm = lazy(()=> import("./Admin/Users/UserForm"));

function AppRoutes() {

  return (
      <Routes>
        <Route path="/" element={<Home />} /> 
        <Route path="/customers" element={<Customers />} />
        <Route path="/about" element={<About />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/terms" element={<Terms />} />
        <Route path="/privacy" element={<Privacy />} />
        <Route path="/views" element={<Views />} />
        <Route path="/features" element={<Features />} />
        <Route path="/alertnotification" element={<Alertnotification />} />
        <Route path="/workflow" element={<Workflow />} />
        <Route path="/documents" element={<Documents />} />
        <Route path="/security" element={<Security />} />
        <Route path="/bianalytics" element={<Bianalytics />} />
        <Route path="/customizations" element={<Customizations />} />
        <Route path="/boardview" element={<BoardView />} />
        <Route path="/signup" element={<Signup />} />
        {/*Hrms Software */}
        <Route path="/hrms-software" element={<HrmsLayout />}>
          <Route index element={<HrmsHome />} />
          <Route element={<CoreHrLayout />}>
              <Route index path="core-hr"  element={<CoreHrHome />} />
              <Route path="employee-management" element={<EmployeeDatabase />} />
              <Route path="employee-self-service-portal" element={<EssPortal />} />
              <Route path="document-management" element={<Document />} />
              <Route path="hr-workflow-management" element={<Workflows />} />
              <Route path="hr-data-analytics" element={<HrReports />} />
          </Route>
        
          {/* 
          <Route element={<FieldForceLayout />}>
            <Route index path="field-force-automation" element={<FieldForce />} />
          </Route>

          <Route element={<AttendanceLayout />}>
              <Route index path="time-and-attendance" element={<AttendanceHome />} />
          </Route>

          <Route element={<PayrollLayout />}>
            <Route index path="payroll-management" element={<PayrollHome />} />
          </Route>

          <Route element={<HrmsExpenseLayout />}>
              <Route index path="expense-management" element={<HrmsExpenseHome />} />
          </Route>

          <Route element={<HrmsTaskLayout />}>
              <Route index path="tasks" element={<HrmsTaskHome />} />
          </Route>

          <Route element={<HrmsEngagementLayout />}>
              <Route index path="employee-engagement" element={<HrmsEngagementHome />} />
          </Route>
          
          <Route element={<HrmsPriceLayout />}>
              <Route index path="qkrhr-pricing" element={<HrmsPricing />}/>
          </Route>
         */}
        </Route>

          {/*QkrHr */}
         <Route path="hrms-software" element={<HrLayout />}>
          {/* <Route index element={<HrHome />} />
          <Route path="core-hr" element={<CoreHr />} />  */}
          <Route path="field-force-automation" element={<FieldForce />} />
          <Route path="time-and-attendance" element={<Attendance />} />
          <Route path="payroll-management" element={<Payroll />} />
          <Route path="expense-management" element={<Expense />} /> 
          <Route path="tasks" element={<Tasks />} /> 
          <Route path="employee-engagement" element={<Engagement />} />
          <Route path="qkrhr-pricing" element={<Pricing /> } />
        </Route> 
      
        {/*QkrExpense */}
        <Route path="qkrexpense" element={<ExpenseLayout />}>
          <Route index  element={<ExpenseHome />} />
        </Route>

        {/*QkrDesk */}
        <Route path="qkrdesk" element={<DeskLayout />}>
            <Route index element={<DeskHome />} />
        </Route>
        
        {/*QkrSales */}
        <Route path="qkrsales" element={<SalesLayout />}>
          <Route index element={<SalesHome />} />
          <Route element={<SalesFeauterLayout />}>
            <Route index path="salesmanagement" element={<SalesManagement />} />
            <Route path="accountsreceivable" element={<AccountsReceivable />} />
            <Route path="billingpayment"  element={<BillingPayment />} />
            <Route path="orderexecution" element={<OrderExecution />} />
            <Route path="invoicing" element={<Invoicving />} />
            <Route path="tenderdeposit" element={<TenderDeposit />} />
            <Route path="salesperformance" element={<SalesPerformance />} />
            <Route path="documentmanagement" element={<DocumentManagement />} />
            <Route path="BusinessManagement" element={<BusinessManagement />} />
          </Route>
        </Route>

        {/*QkrTeams */}
        <Route path="qkrteams" element={<TeamsLayout />}>
            <Route index element={<TeamsHome />} />
        </Route>

        {/*QkrVisit */}
        <Route path="qkrvisit" element={<VisitLayout />}>
          <Route index element={<VisitHome />} />
        </Route>

        {/*QkrTenders */}
        <Route path="qkrtenders" element={<TendersLayout />}>
            <Route index element={<TendersHome />} />
        </Route>

        {/*QkrProject */}
        <Route path="qkrprojects" element={<ProjectLayout />}>
            <Route index element={<ProjectHome />} />
        </Route>

        {/* 
        <Route path="userslist" element={<UserLayout />}>
            <Route index element={<UserList />} />
            <Route path="add" element={<UserForm />} />
            <Route path="edit/:id" element={<UserForm />} />
        </Route> */}
        {/* <Route path="/logins" element={<Login />}/>
        <Route path="/productlist" element={<ProductList />}/>
        <Route path="/postlist" element={<PostData />}/>
        <Route path="/imagecompressor" element={<ImageCompressor />}/>
        <Route path="/imagetopdf" element={<ImageToPdfConverter />}/> */}
        {/* <Route path="/admin" element={<AdminLayout />} /> */}

        <Route path="*" element={<Notfound />} />

      </Routes>
  )
}

export default AppRoutes