import React, { Suspense, useEffect } from "react";
import { useLocation } from "react-router-dom";
import "./App.scss";
import Header from "./shared/Header/Header";
import Footer from "./shared/Footer/Footer";
import Loading from "./utility/Loading/Loading";
import AppRoutes from "./AppRoutes";

function App() {

  const location = useLocation();
  const allowedPaths = [ 
    '/','/hrms-software','/hrms-software/','/qkrexpense','/qkrsales','/qkrteams', '/qkrvisit', '/qkrdesk', '/qkrtenders',
    '/qkrprojects', '/about', '/customers', '/contact', '/terms', '/privacy', '/views', '/features', '/alertnotification', 
    '/workflow','/documents', '/security', '/bianalytics', '/customizations', '/mobileapp', '/boardview', '/signup', '*' 
  ];
  const isDisplayHeader = allowedPaths.includes(location.pathname);

  // const location = useLocation();
  // const allowedPaths = [
  //   '/', '/about', '/customers', '/contact', '/terms', '/privacy',
  //   '/views', '/features', '/alertnotification', '/workflow', '/documents',
  //   '/security', '/bianalytics', '/customizations', '/mobileapp', '/boardview', 
  //   '/signup', '*'
  // ];
  // const matchWildcardPath = (path) => {
  //   return allowedPaths.includes('*') || allowedPaths.includes(path);
  // };
  // const isDisplayHeader = matchWildcardPath(location.pathname);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);
 
  return (
    <>
      {isDisplayHeader && (
          <Header />
      )}
      <Suspense fallback={<Loading />}>
          <AppRoutes/>
      </Suspense>
      <Footer />
    </>
  );
}

export default App;