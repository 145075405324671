import React,{useState, useEffect} from 'react';
import './Footer.scss';
import { Link } from 'react-router-dom'; 
import { Container } from "react-bootstrap";
import ImagesUrl from "../../assets/Index";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebookF, faLinkedinIn, faTwitter } from '@fortawesome/free-brands-svg-icons';
import { faChevronUp } from '@fortawesome/free-solid-svg-icons';
// import CookieConsent from '../../components/CookieConsent/CookieConsent';

function Footer() {
  const [isScrolled, setIsScrolled]=useState(false);

  const handelScrollTop = ()=>{
    window.scrollTo({top:0,behaviar:"smooth"});
  }

   useEffect (()=> {
    const handleScroll =()=> {
        setIsScrolled(window.scrollY >= 50);
     }
     window.addEventListener('scroll', handleScroll);

     return()=>{
      window.removeEventListener('scroll',handleScroll);
     }
   },[]);

    return (
        <>
           {/* <CookieConsent/> */}
            <footer className="footer-section">    
                  <div style={{display: isScrolled ? 'block':'none'}}>
                        <span className="bottomTop" onClick={handelScrollTop}><FontAwesomeIcon icon={faChevronUp}/></span>  
                  </div>
                  <Container>
                    <div className="row">
                      <div className="col-lg-4">
                        <Link to="/" className="d-flex f-logo">
                          <div className="flex-shrink-0">
                              <img src={ImagesUrl.QkrbizLogo} alt="qkrbiz" />
                          </div>
                          <div className="flex-grow-1">
                              krbiz   
                          </div>
                        </Link>
                          <p className="mt-4">Leverage the expertise of industry veterans from IIT and IIM with over 30 years of experience to achieve greater success.</p>
                          <h5 className="mb-3 mt-1">Hexlog Infotech</h5>
                          <p className="mb-0">91-11-46522453</p>
                          <p>sales@qkrbiz.com</p>
                          <ul className="list-unstyled social-network">
                            <li><a href="https://www.facebook.com/quikrbizERP" className="icoFacebook" title="Facebook"><FontAwesomeIcon icon={faFacebookF}/></a></li>
                            <li><a href="http://twitter.com/IntecPvt" className="icoTwitter" title="Twitter"><FontAwesomeIcon icon={faTwitter}/></a></li>
                            <li><a href="http://www.linkedin.com/company/quikr-biz/" className="icoLinkedin" title="Linkedin"><FontAwesomeIcon icon={faLinkedinIn}/></a></li>
                          </ul>	                       
                          <div className="apps-link mt-4">
                              <a href="https://play.google.com/store/apps/details?id=com.intec.qkrbiz" 
                                target="_blank" 
                                rel="noopener noreferrer" 
                                className="d-flex play-store" 
                                style={{ marginRight: '1rem' }}>
                                <span className="flex-shrink-0">
                                  <img src={ImagesUrl.GooglePlay} alt="Google Play" />
                                </span>
                                <span className="flex-grow-1">
                                  <p>GET IT ON</p>
                                  <h5>GOOGLE PLAY</h5>
                                </span>
                              </a>

                              <a href="https://apps.apple.com/app/id1570623057" 
                                target="_blank" 
                                rel="noopener noreferrer" 
                                className="d-flex play-store">
                                <span className="flex-shrink-0">
                                  <img src={ImagesUrl.AppleIcon} alt="Apple Icon" />
                                </span>
                                <span className="flex-grow-1">
                                  <p>GET IT ON</p>
                                  <h5>APP STORE</h5>
                                </span>
                              </a>
                          </div>
                      </div>
                      <div className="col-lg-2 col-sm-6">            
                          <h5>Products</h5>
                          <ul className="list-unstyled">
                            <li><Link to="/hrms-software">QkrHR</Link></li>
                            <li><Link to="/qkrvisit">Qkrvisit</Link></li>
                            <li><Link to="/qkrexpense">Qkrexpense</Link></li>
                            <li><Link to="/qkrdesk">Qkrdesk</Link></li>
                            <li><Link to="/qkrprojects">Qkrproject</Link></li>
                            <li><Link to="/qkrsales">Qkrsales</Link></li>
                            <li><Link to="/qkrtenders">Qkrtenders</Link></li>
                            <li><Link to="/qkrteams">Qkrteams</Link></li>
                          </ul>
                      </div>
                      <div className="col-lg-2 col-sm-6">
                        <div className="headings"><h5>More</h5></div>
                        <ul className="list-unstyled">
                          <li><Link to="/hrms-software/core-hr">Core HR</Link></li>
                          <li><Link to="/hrms-software/time-and-attendance">Time and Attendance</Link></li>
                          <li><Link to="/hrms-software/field-force-automation">Field Force Automation</Link></li>
                          <li><Link to="/hrms-software/expense-management">Expenses</Link></li>
                          <li><Link to="/hrms-software/payroll-management">Payroll</Link></li>
                          <li><Link to="/hrms-software/tasks">Tasks</Link></li>
                          <li><Link to="/qkrsales">Sales Order</Link></li>
                          <li><Link to="/qkrsales/salesmanagement">Sales Management</Link></li>
                          <li><Link to="/qkrsales/billingpayment">Billing & Payment Terms</Link></li>
                          <li><Link to="/qkrsales/salesperformance">Sales Performance</Link></li>
                          <li><Link to="/qkrsales/tenderdeposit">Tender Terms</Link></li>
                          <li><Link to="/qkrsales/invoicing">Invoicing</Link></li>
                        </ul>
                      </div>
                      <div className="col-lg-2 col-sm-6">
                        <div className="headings"> <h5>Features</h5></div>
                          <ul className="list-unstyled">
                            <li><Link to="/features">Spreadsheet Features</Link></li>
                            <li><Link to="/alertnotification">Alerts & Notifications</Link></li>
                            <li><Link to="/workflow">Automate workflow</Link></li>
                            <li><Link to="/documents">Documents</Link></li>
                            <li><Link to="/security">Data Security</Link></li>
                            <li><Link to="/bianalytics">BI & Analytics</Link></li>
                            <li><Link to="/customizations">Customizations</Link></li>
                            {/* <li><Link to="/mobileapp">Mobile App</Link></li> */}
                            <li><Link to="/views">Views</Link></li>
                            <li><Link to="/boardview">Board View</Link></li>
                          </ul>
                      </div>
                      <div className="col-lg-2 col-sm-6">
                        <div className="headings"><h5>Company</h5></div>
                        <ul className="list-unstyled">
                            <li><Link to="/about">About</Link></li>
                            <li><Link to="/blog">Blog</Link></li>
                            <li><Link to="/customers">Clients</Link></li>
                            <li><Link to="/partner">Partner</Link></li>
                            <li><Link to="/contact">Contact</Link></li>
                            <li><Link to="/terms">Terms & Conditions</Link></li>
                            <li><Link to="/privacy">Privacy & Policy</Link></li>
                        </ul>
                        {/* <ThemedButton variant="primary" children="Primary Button"/>
                        <ThemedButton variant="secondary" children="Secondary Button"/> */}
                      </div>
                    </div>
                  </Container>
            </footer>
        </>
    )
}

export default Footer