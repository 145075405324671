import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { useLocation } from 'react-router-dom';

const RouteSpecificMeta = ({ title, description, canonical, SchemaData, image }) => {
  const location = useLocation();

  useEffect(() => {
    // console.log('Url', location.pathname);
    // console.log('Title', location.title);
    // console.log('Description', location.description);
    // console.log('Canonical', location.canonical);
    // console.log('Image URL:', image);
  }, [location.pathname,title,description,canonical ]);

  return (
    <Helmet>
        <title>{title}</title>
        <meta name="title" content={title} />
        <meta name="description" content={description} />
        {canonical && <link rel="canonical" href={canonical} />}

        <meta property="og:locale" content="en"/>
        <meta property="og:site_name" content="Qkrbiz"/>
        <meta property="og:type" content="website" />
        {image && <meta property="og:image" content={image} />}
        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />
        
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:creator" content="@QkrbizDotCom"/>
        <meta name="twitter:site" content="@QkrbizDotCom"/>
        <meta name="twitter:title" content={title} />
        <meta name="twitter:description" content={description} />

        {image && <meta name="twitter:image" content={image} />}
        <script type="application/ld+json">
          {SchemaData && JSON.stringify(SchemaData)}
        </script>
    </Helmet>
  );
};

export default RouteSpecificMeta;

// {image && <meta property="og:image" content={`${window.location.origin}${image}`} />} 
// const RouteSpecificMeta = ({title, description, keywords, canonical}) => {
//   return (
//     <>
//       <Helmet>
//         <title>{title}</title>
//         <meta name="description" content={description} />
//         <meta name="keywords" content={keywords} />
//         {canonical && <link rel="canonical" href={canonical} />}
//         {image && <meta property="og:image" content={image} />}
//         <meta property="og:image:width" content="875" />
//         <meta property="og:image:height" content="526" />
//         <meta property="og:title" content={title} />
//         <meta property="og:description" content={description} />
//         <meta property="og:type" content="website" />
//         {image && <meta name="twitter:card" content="summary_large_image" />}
//         <meta name="twitter:title" content={title} />
//         <meta name="twitter:description" content={description} />
//         {image && <meta name="twitter:image" content={image} />}
//       </Helmet>
//     </>
//   )
// }
// export default RouteSpecificMeta;